<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Identification Type</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12" class="mb-3">
        <vs-input class="w-full" label="Name (*)" v-model="payload.name" />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("name")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module

import identificationTypeStore from "@/store/identification-type";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";

export default {
  metaInfo: {
    title: "Add Identification Type",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  components: {
    vSelect,
    StarRating,
  },

  data: () => ({
    payload: {
      name: "",
    },
  }),
  computed: {
    brands() {
      return this.$store.state.brandManagement.brands;
    },
  },

  methods: {
    setRating: function (rating) {
      this.payload.rating = rating;
    },
    async storeData() {
      const formData = new FormData();
      formData.set("name", this.payload.name);

      try {
        const response = await this.$store.dispatch(
          "identificationType/storeIdentificationType",
          formData
        );
        if (!response.success) {
          throw error(response.message);
        }
        this.$vs.notify({
          title: "Successful",
          text: response.msg_status,
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
        });

        this.$router.push("/identification-type");
      } catch (error) {
        console.log(error);
        this.$catchErrorResponse(error);
      }
    },
  },

  async created() {
    if (!identificationTypeStore.isRegistered) {
      this.$store.registerModule("identificationType", identificationTypeStore);
      identificationTypeStore.isRegistered = true;
    }
  },
};
</script>

<style></style>
